import { EllipsisMenu } from '@weareopenr/component-library';
import { IBrandResponse } from 'src/api/getBrands/types';
import { Channels } from 'src/components/Channels/Channels';
import styles from '../Dashboard.module.scss';
import { getLogo } from 'src/utils/getLogo';

export const mapToTable = (data: IBrandResponse[], onView: (id: string) => void) => {
  return data.map((brand) => {
    const actionOptions = [
      {
        id: brand.brand_id,
        name: 'View',
        onOptionClick: () => onView(brand.brand_id),
      },
    ];

    return {
      id: brand.brand_id,
      logo: (
        <img src={getLogo(brand.logo_url, '')} alt={`${brand.name} logo`} className={styles.logo} />
      ),
      name: brand.name,
      channels: <Channels brand={brand} />,
      venues: brand.venues > 0 ? brand.venues.toString() : 'No venues',
      actions: <EllipsisMenu options={actionOptions} variant="outline" />,
    };
  });
};
